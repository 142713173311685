<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 10.5 14">
    <path
      :style="iconStyle"
      d="M4.71,1.468a.657.657,0,0,0,1.079,0C9.763-4.292,10.5-4.883,10.5-7a5.25,5.25,0,0,0-5.25-5.25A5.25,5.25,0,0,0,0-7C0-4.883.737-4.292,4.71,1.468Zm.54-6.28A2.188,2.188,0,0,1,3.063-7,2.188,2.188,0,0,1,5.25-9.187,2.188,2.188,0,0,1,7.438-7,2.188,2.188,0,0,1,5.25-4.812Z"
      transform="translate(0 12.25)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '14',
    },
    width: {
      type: String,
      default: '10.5',
    },
    color: {
      type: String,
      default: 'label-text',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
